import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('new-button')
export class NewButton extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
        a.link {
            display: flex;
            flex-direction: row;
            border-radius: var(--digilean-border-radius, 8px);
            background: var(--digilean-secondary-background);
            box-shadow: var(--digilean-box-shadow);
            /* box-shadow: 0px 3px 0px 0px rgba(226, 228, 231, 0.75); */
            text-decoration: none;
        }
        a.link:hover {
            box-shadow: var(--digilean-box-shadow-hover);
        }
        a.link .iconlink {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            background: var(--digilean-icon-background, var(--digilean-primary));
            height: 4.5rem;
            flex-basis: 4.5rem;
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: var(--digilean-border-radius, 8px) 0 0 var(--digilean-border-radius, 8px);
        }
        a.link fa-icon {
            color: var(--digilean-icon-color, var(--digilean-secondary-text));
            margin: auto;
            --digilean-fa-icon-width: 3.5rem;
            --digilean-fa-icon-height: 3.5rem;
        }
        .textlink {
            color: var(--digilean-secondary-text);
            display: inline-block;
            vertical-align: center;
            line-height: 4.5rem;
            font-size: 1.5rem;
            flex-basis: 10rem;
            flex-grow: 1;
            flex-shrink: 0;
            border-radius: 0 var(--digilean-border-radius, 8px) var(--digilean-border-radius, 8px) 0;
        }
    `;

    @property({attribute: false})
    icon: any = null

    @property({attribute: true})
    url = ""

    @property({attribute: true})
    title = ""

    render() {
        
        return html`
            
            <a href=${this.url} class="link">
                <span class="iconlink">
                    <fa-icon  
                        .icon=${this.icon}
                        title="New Task">
                    </fa-icon>
                </span>
                <span class="textlink">
                    ${this.title}
                </span>
            </a>
            
        `;
    }
}