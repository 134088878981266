<script setup lang="ts">
    import { ref } from "vue"

    const count = ref(0)
</script>

<template>
    <h1>Incidents</h1>
</template>

<style scoped>

</style>
