import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { ReplyableCommentsViewModel, ReplyableComment } from "@api"
import "@common/components/Comments/CommentedBy"

@customElement('replyable-comment-viewer')
export class ReplyableCommentView extends LitElement {
    
    static styles = css`
        :host {
            display: block;
        }
        div.replies {
            margin: 0 0 0 0.5rem;
        }
    `

    @property({attribute: false})
    comment: ReplyableComment

    render() {
        if (this.comment) {
            if (this.comment.replies && this.comment.replies.length > 0) {
                return html`
                    <comment-simple-viewer .comment=${this.comment}></comment-simple-viewer>
                    <div class="replies">
                        ${this.comment.replies.map(r => {
                            return html`
                                <replyable-comment-viewer .comment=${r}></replyable-comment-viewer>
                            `
                        })}
                    </div>
                `
            } else {
                return html`
                    <comment-simple-viewer .comment=${this.comment}></comment-simple-viewer>
                `
            }
            
        }
        return html`
            <span>N/A</span>
        `
    }
}

