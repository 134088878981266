import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import type { BoardTaskComment } from "@api"

@customElement('comment-simple-viewer')
export class CommentSimpleView extends LitElement {
    
    static styles = css`
        :host {
            display: block;
            background: var(--digilean-secondary-background);
            color: var(--digilean-secondary-text);
            border-radius: var(--digilean-border-radius);
            padding: 0.2rem;
            margin: 0.3rem;
        }
        div.header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            line-height: 1.6rem;
            vertical-align: middle;
        }
        div.body {
            text-align: left;
            /* border: 1px solid var(--digilean-grey-smoke); */
            /* padding: 0.3rem;
            border-radius: var(--digilean-border-radius); */
            margin: 0.2rem 0 0 0;
            /* background: var(--digilean-primary-background); */
            color: var(--digilean-primary-text);
        }
        date-viewer {
            color: var(--digilean-tertiary-text);
        }
        p { margin: 5px 0; }
    `;

    @property({attribute: false})
    comment: BoardTaskComment

    render() {
        if (this.comment) {
            return html`
                <div class="header">
                    <commented-by-user .userid=${this.comment.commentedByUserId}></commented-by-user>
                    <date-viewer .date=${this.comment.commentDate} fontsize="x-small"></date-viewer>
                </div>
                <div class="body">
                    ${unsafeHTML(this.comment.comment)}
                </div>
        `
        }
        return html`
            <span>N/A</span>
        `
    }
}

