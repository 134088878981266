import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { observe } from "@common/directives/observableDirective"
import { userProfile } from "@common/stores/userStore"
import type { UserFullProfile } from "@api"

@customElement('logged-in-profile-image-viewer')
export class LoggedInProfileImageViewer extends LitElement {
    static styles = css`
        :host {
            display: block;
        }
    `
    @property({attribute: false})
    initials = false
    render() {
        return observe(userProfile, (userProfile: UserFullProfile) => {
            
            if (userProfile) {
                return html`
                    <profile-image-viewer .user=${userProfile.user!} .initials=${this.initials}></profile-image-viewer>
                `
            }
            return html`
                <span>whut</span>
            `
        })
    }
}
