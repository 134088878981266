import type { AspNetErrorResponse } from "@common/model/aspNetErrorResponse"

export const handleErrorsAspNet = (res: AspNetErrorResponse) => {
    const allErrorMsgs: string[] = []
    if (res.errors) {
        for (let key in res.errors) {
            let errors = res.errors[key]
            if (errors && Array.isArray(errors))
                allErrorMsgs.push(...errors)
        }
    }
    return allErrorMsgs
}