import { BehaviorSubject } from "rxjs"

export interface NotificationRequest {
    title: string,
    body?: string,
    taskId?: number
}

const permissionSubject = new BehaviorSubject<NotificationPermission>("default")
export const notificationPermission = permissionSubject.asObservable()
export const setPermission = (p: NotificationPermission) => permissionSubject.next(p)

const notificationsSubject = new BehaviorSubject<NotificationRequest>({title: ""})
export const notificationsQueue = notificationsSubject.asObservable()
export const queueNotification = (title: string, body?: string, taskId?: number) => {
    var notReq: NotificationRequest = { title, body, taskId }
    notificationsSubject.next(notReq)
}