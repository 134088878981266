export let isAppBadgeSupported = false
/// @ts-ignore
if (navigator.setAppBadge)
    isAppBadgeSupported = true

export function setBadgeCount(count: number) {
    console.log("isSupported", isAppBadgeSupported)
    if (!isAppBadgeSupported)
        return
    /// @ts-ignore
    const res = navigator.setAppBadge(1)
    return res
}
