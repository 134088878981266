import { MessagePayload } from "@firebase/messaging"

const digiLeanIcon = "https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-192.png"
const digiLeanBadge = "https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-96.png"

export const pushNotification = (payload: MessagePayload, swReg: ServiceWorkerRegistration) => {
    let title = "push notify"
    const options: NotificationOptions = {
        body: "test probably",
        icon: digiLeanIcon,
        badge: digiLeanBadge,
        actions: [
            { action: "open", title: "open" },
            { action: "close", title: "close" }
        ],
        tag: "digilean-tag"
    }

    if (payload.data) {
        options.body = JSON.stringify(payload.data)
    }
    
    swReg.showNotification(title, options)
}