import type { BoardTask, BoardTaskAttachment, BoardTaskComment, DateOptions, PersonalWorkList, ReplyableCommentsViewModel, TaskMoved } from "@api"
import backend from "@common/services/backendHttp"
import { uploadFileBlobStorage, getFileUrl } from "@common/services/documentService"
import { getDayOfWeek } from "@common/services/helper"
import type { AttachmentsView, FileView } from "@common/model/types"

export const getMyTasks = async () => {
    const offset = getTimeZoneOffset()
    const my = await backend.get<PersonalWorkList>(`api/task/getmytasks/${offset}`)
    return my
}

const getTimeZoneOffset = () => {
    var timeNow = new Date();
    var offset = timeNow.getTimezoneOffset() / 60 * (-1);
    return offset
}

export const getBoardTask = async (id: number) => {
    const task = await backend.get<BoardTask>(`api/task/${id}`)
    return task
}

export const registerNewTask = async (task: BoardTask) => {
    if (task.boardId === 0)
        task.boardId = null
    const id = await backend.post("api/task", task)
    return id
}

export const canEditTask = async (id: number) => {
    const canEdit = await backend.get<boolean>(`api/task/CanEdit/${id}`)
    return canEdit
}

export const updateTask = async (task: BoardTask) => {
    const ids = await backend.put<number[]>(`api/task/${task.id}`, task)
    return ids
}

export const updateTaskDate = async (id: number, date: string) => {
    const dayOfWeek = `${getDayOfWeek(date)}`
    const opts: DateOptions = {
        date,
        dayOfWeek
    }
    const moved = await backend.put<TaskMoved>(`api/task/${id}/UpdateDate`, opts)
    return moved
}

export const deleteTask = async (id: number) => {
    await backend.delete(`api/task/${id}`)
}

export const getTaskCommentWithReplies = async (id: number) => {
    const comments = await backend.get<ReplyableCommentsViewModel>(`api/task/GetCommentWithReplies/${id}`)
    return comments
}
export const postComment = async (comment: BoardTaskComment) => {
    const cmt = await backend.post<BoardTaskComment>(`api/task/${comment.boardTaskId}/addcomment`, comment)
    return cmt
}
export const GetTaskComments = async (id: number) => {
    const comments = await backend.get<BoardTaskComment[]>(`api/task/GetComments/${id}`)
    return comments
}

export const GetAttachments = async (id: number) => {
    const attachments = backend.get<BoardTaskAttachment[]>(`api/task/GetAttachments/${id}`)
    return attachments
}

export const AddAttachment = async (id: number, file: File) => {
    const resUpload = await uploadFileBlobStorage(file)
    const fileInfo = resUpload[0]
    const att = await backend.post<BoardTaskAttachment>(`api/task/${id}/AddAttachment`, fileInfo)
    return att
}

export const getAttAndThumbnails = async (taskid: number): Promise<AttachmentsView> => {
    const allAttachments = await GetAttachments(taskid)
    if (!allAttachments || !Array.isArray(allAttachments) || allAttachments.length == 0)
        return {
            files: [],
            images: []
        }

    const attachmentImages = allAttachments.filter(a => a.fileInfo?.isImage)
    const attachmentFiles = allAttachments.filter(a => !a.fileInfo?.isImage)

    const imagesTemp = await Promise.all(attachmentImages.map(async (a) => {
        if (a.fileInfo && a.fileInfo.isImage) {
            let url = ""
            if (a.fileInfo.hasPreview) {
                url = await getFileUrl(a.fileInfo.previewFileName!, a.fileInfoId!)
            }
            if (!url)
                url = await getFileUrl(a.fileInfo.fileName!, a.fileInfoId!)
            return {
                filename: a.fileInfo.fileName,
                extension: a.fileInfo.fileExtension,
                url
            } as FileView
        }
        return null
    }))
    
    const images = imagesTemp.reduce((all: FileView[], f: FileView | null, i: number) => {
        if (f)
            all.push(f)
        return all
    }, [])
    
    const files = attachmentFiles.map(a => {
        if (!a.fileInfo)
            return null
        const filename = a.fileInfo.name
        const extension = a.fileInfo.fileExtension
        const url = a.fileInfo.fileUrl
        return { filename, extension, url} as FileView
    }).reduce((all: FileView[], f: FileView | null, i: number) => {
        if (f)
            all.push(f)
        return all
    }, [])

    return {
        images,
        files
    }
}