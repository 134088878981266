import {LitElement, html, css} from "lit"
import {customElement, property, state} from "lit/decorators.js"
import { formatDateOnly, formatDateTime } from "@common/services/datetime/dateTimeFormattingLocale"

@customElement('date-viewer')
export class DateViewer extends LitElement {
        
    private _dateString: string

    @state()
    _date: Date | null = null

    static styles = css`
        :host {
            display: inline;
        }
        span {
            overflow-wrap: break-word;
        }
    `
    constructor() {
        super()
        this._dateString = ""
    }
    get date(): string {
        return this._dateString;
    }

    @property({attribute: true})
    set date(value: string) {
        this._dateString = value
        try {
            this._date = new Date(value)
        }
        catch(e) {}
    }
    render() {
        
        if (!this._date)
            return html`N/A`
        
        let formattedDate = formatDateOnly(this._date)
        
        this.setAttribute("title", formatDateTime(this._date)) // tooltip
        return html`
            ${formattedDate}
        `
    }
}