import { Workbox } from "workbox-window"
import { updatedVersion, doUpdateVersion } from "@common/stores/appstate"
import config from "@common/config"

let wb: Workbox
let swRegistration: ServiceWorkerRegistration | undefined


const setupWb = () => {
    wb = new Workbox("/sw.js")
    
	wb.addEventListener("waiting", (event) => {
        console.log(event)
		updatedVersion.next(true)
	})
    wb.addEventListener("controlling", (event) => {
        console.log(event)
        window.location.reload()
    })
    
	wb.register().then(reg => swRegistration = reg)
    
    doUpdateVersion.subscribe(doUpdate => {
        if (doUpdate) {
            console.log("doupdate")
            wb.messageSkipWaiting()
        }
    })
}

export const readyForData = () => {
    wb.messageSW({readyForData: true})
}

console.log("useSW", config.useSw)
if ('serviceWorker' in navigator && config.useSw) {
	setupWb()
}
