<script setup lang="ts">
    import { ref} from "vue"
    import { authUser } from "@common/stores/userStore"
    import { login } from "@common/authentication/authenticator"
    import { DigiLeanAuthUser } from "@common/model/types"
    let ua = ref<DigiLeanAuthUser>({loggedIn: false})
    authUser.subscribe(u => ua.value = u)

</script>
<template>

    <article>
        <digilean-logo></digilean-logo>
        <div v-if="!ua.loggedIn">
            <digilean-button @click="login">Login</digilean-button>
        </div>
    </article>
    
</template>
<style scoped>
    article {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
    digilean-logo {
        --digilean-image-height: 10rem;
        --digilean-image-width: 10rem;
    }
</style>