import type { BlobInfo, FileInfo } from "@api"
import backend from "@common/services/backendHttp"
const baseUrl = "api/document"

interface Cache {
    expire: string
    data: string
}
type CachesObj = { 
    [key: string]: Cache
}
let cachedFileUrls:CachesObj = {}

const tryGetCachedUrl = (name: string, id: string) => {
    const key = name + id
    const cache = cachedFileUrls[key]
    if (!cache)
        return null
    let expireDate = new Date(cache.expire)
    let now = new Date()
    if (expireDate > now)
        return cache.data
    
    Reflect.deleteProperty(cachedFileUrls, key)
    return null
}

const addToCache = (name: string, id: string, data: string) => {
    if (!name || !id || !data) return
    const key = name + id
    const expire = getExpireDateFromUrl(data)
    cachedFileUrls[key] = { expire, data }
}
const getExpireDateFromUrl = (url: string) => {
    try {
        const urlObj = new URL(url)
        if (urlObj.searchParams && urlObj.searchParams.has("se")) {
            let expireDate = urlObj.searchParams.get("se")
            return expireDate
        }
    } catch(e) {
        console.log(e)
    }
    
    let nowPlus9 = new Date()
    nowPlus9.setHours(nowPlus9.getHours() + 9)
    return nowPlus9.toISOString()
}
// tn_b8bb8fa7-d701-4e1e-8062-d63dab7d89a4-P1000081.JPG
// tn_b8bb8fa7-d701-4e1e-8062-d63dab7d89a4-P1000081.JPG
export const getFileUrl = async (fileName: string, fileId:string): Promise<string> => {
    const apiMethodUrl = baseUrl + "/getFileUrl"

    const cachedUrl = tryGetCachedUrl(fileName, fileId)
    if (cachedUrl)
        return cachedUrl

    const blobInfo: BlobInfo = {
        blobName: fileName,
        fileInfoId: fileId
    }
    const fileUrl = await backend.post<string>(apiMethodUrl, blobInfo)
    addToCache(fileName, fileId, fileUrl)
    return fileUrl
}

export const getFile = async (id: string) =>  {
    const url = `${baseUrl}/getFile/${id}`
    const file = await backend.get(url)
    return file
}

export const getAll = async () => {
    const files = await backend.get(baseUrl)
    return files
}

export const uploadImageToBlobStorageNoPreview = async (file: Blob) => {
    const url = baseUrl + "/PostImageBlobUploadNoPreview"
    let fd = new FormData()
    fd.append('file', file)
    const res = await backend.post(url, fd)
    return res
    // transformRequest: angular.identity,
    // headers: { 'Content-Type': undefined }
}

export const uploadFileBlobStorage = async (file: File) => {
    const url = baseUrl + "/PostBlobUpload"
    const res = await backend.postFile<FileInfo[]>(url, file)
    return res
}
