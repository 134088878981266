<script setup lang="ts">
    import { PropType, onMounted, ref } from "vue"
    import type { BoardTask, BoardTaskComment, ReplyableCommentsViewModel } from "@api"
    import * as service from "@common/services/taskService"

    const props = defineProps({
        task: {
            type: Object as PropType<BoardTask>,
            required: true,
            default: {}
        }
    })

    const commentsVm = ref<ReplyableCommentsViewModel>({ total:0, comments: []})
    onMounted(() => {
        getComments()
    })
    let getComments = async () => {
        if (props.task.id)
            commentsVm.value = await service.getTaskCommentWithReplies(props.task.id!)
        else
            console.log("no prop id")
    }
    let saveTopLevel = async (e: Event) => {
        ///@ts-ignore
        const comment: BoardTaskComment = e.detail.comment
        comment.boardTaskId = props.task.id
        await service.postComment(comment)
        getComments()
    }
</script>

<template>
    <article v-if="props.task">
        <h1>Comments</h1>
        <div>
            {{ props.task.id }}
        </div>
        <top-level-comment @postcomment="saveTopLevel"></top-level-comment>
        <replyable-comment-viewer v-if="commentsVm.comments"
            v-for="comment in commentsVm.comments"
            .comment="comment">
        </replyable-comment-viewer>
    </article>
</template>

<style scoped>
    article {
        color: var(--digilean-secondary-text);
    }
    top-level-comment {
        margin: 0.5rem 0.5rem 0 0.5rem;
    }
</style>

