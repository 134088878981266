import FroalaEditor from "froala-editor"
import { html } from "@common/template/templateLiteral"
export class CommentEditor extends HTMLElement {
    private _content: string = ""
    private _editorEl: Element | null | undefined = null
    private _froala
    private _options = {
        key: "CTD5xD3E1G2A1A8B2wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2C1F4F1F1==",
        attribution: false,
        toolbarButtons: [],
        placeholderText: "",
        imagePaste: false,
        imageMaxSize: 10
    }

    constructor() {
        super()
        this.attachShadow({mode: 'open'})
        this.shadowRoot!.innerHTML = html`
            <style>
                :host {
                    display: block;
                    background: var(--digilean-secondary-background);
                    color: var(--digilean-secondary-text);
                    border-radius: var(--digilean-border-radius);
                }
                div.editor.fr-box.fr-basic.fr-top {
                    border-radius: var(--digilean-border-radius);
                }
                div.editor div.fr-element.fr-view {
                    padding: 7px;
                }
                p { 
                    margin:5px; 
                }
            </style>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/froala-editor/3.2.7/css/froala_editor.pkgd.min.css" rel="stylesheet" type="text/css"></link>
            <div class="editor"></div>
        `
    }
    
    connectedCallback() {
        this._editorEl = this.shadowRoot?.querySelector('.editor')
        this._froala = new FroalaEditor(this._editorEl, this._options)
    }
    disconnectedCallback() {
        this._froala.destroy()
    }
    get content() {
        const html = this._froala.html.get()
        return html
        //return this._content
    }
    set content(value: string) {
        this._content = value
        if (this._froala)
            this._froala.html.set(value)
    }
}

customElements.define('comment-editor', CommentEditor)