import { BehaviorSubject, fromEvent } from "rxjs"

export const appInitialized = new BehaviorSubject(false)
export const docTitle = new BehaviorSubject("DigiLEAN pwa")
export const updatedVersion = new BehaviorSubject(false)
export const doUpdateVersion = new BehaviorSubject(false)

export const openMainMenu = new BehaviorSubject(false)

let storedIsDarkTheme = false
const dtLs = localStorage.getItem("storedIsDarkTheme")
if (dtLs)
    storedIsDarkTheme = JSON.parse(dtLs)
export const isDarkTheme = new BehaviorSubject(storedIsDarkTheme)
isDarkTheme.subscribe(val => {
    localStorage.setItem("storedIsDarkTheme", `${val}`)
})

export const loadingOrSaving = new BehaviorSubject(false)

const isOnlineSubject = new BehaviorSubject<boolean>(navigator.onLine)
export const isOnline = isOnlineSubject.asObservable()

const onlineEvent = fromEvent(window, "online")
const offlineEvent = fromEvent(window, "offline")
onlineEvent.subscribe(val => isOnlineSubject.next(true))
offlineEvent.subscribe(val => isOnlineSubject.next(false))

const isVisibleSubject = new BehaviorSubject<boolean>(!document.hidden)
export const isPageVisible = isVisibleSubject.asObservable()
const visibilitychangeEvent = fromEvent(document, "visibilitychange")
visibilitychangeEvent.subscribe(val => {
    isVisibleSubject.next(!document.hidden)
})