import type { DigiLeanAuthUser } from "@common/model/types"
import type { User } from "oidc-client-ts"

export const formatLoggedInUser = (user: User) => {
    let firstName = user.profile.given_name
    let lastName = user.profile.family_name

    let authUser: DigiLeanAuthUser = {
        loggedIn: true,
        customerId: user.profile.customer_id as number,
        userId: user.profile.sub,
        userName: user.profile.preferred_username,
        displayName: `${firstName} ${lastName}`,
        roles: getRole(user),
        token: user.access_token,
        idToken: user.id_token,
        refreshToken: user.refresh_token
    }
    // authUser.isAdmin = authUser.roles?.includes("Admin")
    // authUser.isKaizenAdmin = authUser.roles?.includes("KaizenAdmin")
    return authUser
}

const getRole = (user: User): Array<string> => {
    if (!user.profile.role)
        return ["user"]
    return Array.isArray(user.profile.role) ? user.profile.role : [user.profile.role]
}
