<script setup lang="ts">
    // import { useQuery, QueryClient } from "@tanstack/vue-query"
    // import { getMyTasks } from "@common/services/taskService"
    import TaskList from "@app/views/Tasks/TaskList.vue"

    import { getMyTasksQuery } from "@common/stores/vueTanStack"
    // const queryClient = new QueryClient({
    //     defaultOptions: { queries: { staleTime: 5000 } },
    // })

    const { isLoading, isError, data, error } = getMyTasksQuery()
    // onMounted(() => {
    //     loadMyTasks() @dblclick="loadMyTasks"
    // })
    
</script>

<template>
    <div v-if="isLoading">----Loading----</div>
    <div v-if="isError">
        <span>{{ error }}</span>
    </div>
    <article v-if="data">
        <TaskList :tasksList="data.today"
            title="Today"
            color="--digilean-info">
        </TaskList>
        <TaskList :tasksList="data.overdue"
            title="Overdue"
            color="--digilean-danger">
        </TaskList>
        <TaskList :tasksList="data.future"
            title="Future"
            color="--digilean-success">
        </TaskList>
    </article>
</template>

<style scoped>
    article {
        background: var(--digilean-primary-background);
        color: var(--digilean-primary-text);
    }
</style>

