<script setup lang="ts">
    import type { UserTask } from "@api"
    import { gotoPath } from "@app/routes"
    import { PropType } from "vue"

    const props = defineProps({
        task: {
            type: Object as PropType<UserTask>,
            required: true
        }
    })
    let gotoTaskView = (id: number) => {
        const path = `/task/${id}`
        gotoPath(path)
    }
</script>

<template>
    <div class="task-wrapper" v-if="props.task">
        <div class="checkbox">
            <digilean-tri-state-box />
        </div>
        <div class="main" @click="() => gotoTaskView(props.task.id!)">
            <div class="title">
                {{ props.task?.title }}
            </div>
            <div class="category">
                <span v-if="props.task?.boardName">
                    {{ props.task.boardName }} |
                </span>
                <span v-if="props.task?.columnCategory">
                    {{ props.task.columnCategory }} |
                </span>
                <span v-if="props.task?.rowCategory">
                    {{ props.task.rowCategory }}
                </span>
            </div>
        </div>
        <div class="dateand">
            <div class="date">
                <date-viewer .date="props.task?.boardDate" fontsize="x-small"></date-viewer>
            </div>
            <div class="type">
                {{ props.task?.taskType }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .task-wrapper {
        display: flex;
        flex-direction: row;
        background: var(--digilean-primary-background);
        color: var(--digilean-secondary-text);
        cursor: pointer;
    }
    /* .task-wrapper:hover {
        background: var(--digilean-transparent-background);
        color: var(--digilean-main-menu-text);
    } */
    .checkbox, .main, .dateand {
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
    
    .checkbox {
        flex-basis: 12%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .main {
        flex-basis: 60%;
    }
    .dateand {
        flex-basis: 25%;
    }
    .category {
        font-weight: 600;
        font-size: x-small;
    }
</style>