import {LitElement, html, css} from "lit"
import {customElement, state, eventOptions} from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"

enum CheckboxState {
    open,
    ok,
    cancel
}

@customElement('digilean-tri-state-box')
export class DigiLeanTriStateBox extends LitElement {

    @state()
    cbstate = CheckboxState.open

    getNextState() {
        if (this.cbstate == CheckboxState.open)
            return CheckboxState.ok
        if (this.cbstate == CheckboxState.ok)
            return CheckboxState.cancel
        return CheckboxState.open
    }

    @eventOptions({capture: true})
    _onClick(e) {
        this.cbstate = this.getNextState()
    }
    static styles = css`
        :host {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            display: inline-block;
            user-select: none;
            cursor: pointer;
            /* -webkit-tap-highlight-color: transparent; */
        }
        
        #outer-box {
            background: var(--digilean-main-menu-text);
            display: inline-flex;
            box-sizing: border-box;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            transform: translate3d(0, 0, 0);
            position: relative;
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            border-radius: 5px;
            transform: scale(1);
            vertical-align: middle;
            border: 2px solid var(--digilean-input-border);
            transition: all 0.6s ease;
        }
        
        svg {
            position: absolute;
            z-index: -1;
            height: calc(var(--digilean-icon-height, 2rem) * 0.6);
            width: calc(var(--digilean-icon-width, 2rem) - 0.6);
            fill: none;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            /* transition-delay: 0.1s; */
            transform: translate3d(0, 0, 0);
        }
        svg#check {
            stroke: var(--digilean-white);
            transform: scale(0.2);
            transition: all 1s ease;
        }
        svg#cross {
            transform: scale(0);
            transition: all 0.8s ease;
            stroke: var(--digilean-main-menu-background);
        }
        svg.circle {
            transform: scale(0);
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            transition: all 1s ease;
            /* transition: fill 250ms cubic-bezier(.4,.0,.23,1); */
        }
        #outer-box.ok, #outer-box.cancel {
            border: none;
            background: transparent
        }
        
        #outer-box.ok svg#circle-check {
            z-index: 1;
            transform: scale(1);
            fill: var(--digilean-button-success);
        }
        #outer-box.cancel svg#circle-cross {
            transform: scale(1);
            z-index: 1;
            fill: var(--digilean-danger);
        }
        #outer-box.ok svg#check {
            transform: scale(1);
            stroke-dashoffset: 0;
            z-index: 1;
        }
        #outer-box.cancel svg#cross {
            stroke: var(--digilean-white);
            transform: scale(1);
            stroke-dashoffset: 0;
            z-index: 1;
        }

        .bubble {
            height: var(--digilean-icon-height, 2rem);
            width: var(--digilean-icon-width, 2rem);
            z-index: -21;
            transform: scale(0);
            opacity: 1;
        }
        #outer-box.ok #bubble-check {
            fill: var(--digilean-button-success-border);
            z-index: 1;
            transform: scale(3.2);
            opacity: 0;
            transition: all 1s ease-out;
        }
        #outer-box.cancel #bubble-cross {
            /* fill: var(--digilean-danger); */
            stroke: var(--digilean-danger);
            stroke-width: 10;
            z-index: 1;
            transform: scale(1.6);
            opacity: 0;
            transition: all 1s ease-out;
        }
        /* :host(:hover) #outer-box {
            border-color: #3c53c7;
        } */
        @keyframes check {
            50% {
                transform: scale(1.2);
            }
        }
    `
    render() {
        const classes = {
            "open": this.cbstate == CheckboxState.open,
            "ok": this.cbstate == CheckboxState.ok,
            "cancel": this.cbstate == CheckboxState.cancel
        }
        return html`
            <div id="outer-box" @click=${this._onClick} class=${classMap(classes)}>
                <!--ok-->
                <svg id="bubble-check" class="bubble" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="circle-check" class="circle" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="check" viewbox="0 0 13 13">
                    <polyline points="2 6 5 10 11 3"></polyline>
                </svg>

                <!--cancel-->
                <svg id="bubble-cross" class="bubble" viewbox="0 0 120 120">
                    <circle cx="60" cy="60" r="60"></circle>
                </svg>
                <svg id="circle-cross" class="circle" viewbox="0 0 12 12">
                    <circle cx="6" cy="6" r="6"></circle>
                </svg>
                <svg id="cross" viewbox="0 0 12 12">
                    <polyline points="2 2 10 10"></polyline>
                    <polyline points="2 10 10 2"></polyline>
                </svg>
            </div>
        `
    }
}
