import { setPermission, notificationsQueue, notificationPermission } from "@common/stores/notifications"
import type { NotificationRequest } from "@common/stores/notifications"

//import { swRegistration } from "../swloader"
let swRegistration: ServiceWorkerRegistration
navigator.serviceWorker.ready.then((reg) => {
    swRegistration = reg
})

const digiLeanIcon = "https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-192.png"
const digiLeanBadge = "https://digileanfiles.blob.core.windows.net/public-assets/logos/digilean-logo-96.png"
const taskIcon = "https://digileanfiles.blob.core.windows.net/public-assets/icons/digilean-task-192.png"

let permission: NotificationPermission = "denied"

const initialize = () => {
    if (!("Notification" in window)) {
        console.log("notifications not supported")
        setPermission("denied")
        return
    }
    permission = Notification.permission
    setPermission(Notification.permission)
    if (Notification.permission === "granted") {
        notificationsQueue.subscribe(val => {
            if (val.title)
                notify(val)
        })
    }
    notificationPermission.subscribe(per => {
        permission = per
    })
    // else if (Notification.permission === "denied") { 
    //     console.log("notifications denied")
    //     return
}

export const enablePushNotification = async () => {
    if (permission !== "granted")
        await requestNotificationsPermission()
}

const requestNotificationsPermission = async () => {
    permission = await Notification.requestPermission()
    //if (permission === 'denied' || permission === 'default')
    setPermission(permission)
}

export const sendNotification = () => {
    let options: NotificationOptions = {
        badge: digiLeanBadge,
        icon: digiLeanIcon,
        tag: "178637",
        body: "This is only a test message for task assigned"
    }
    try {
        swRegistration.showNotification("Test", options)
    } catch (error) {
        console.error(error)
    }
    
}

const notify = async (not: NotificationRequest) => {
    if (permission !== "granted")
        return
    
    let options: NotificationOptions = {
        badge: digiLeanBadge,
        icon: taskIcon,
        tag: `${not.taskId}`,
        body: not.body,
    }
    
    swRegistration.showNotification(not.title, options)
}

initialize()