import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('image-viewer')
export class ImageViewer extends LitElement {
    
    static styles = css`
        :host {
            display: inline-block;
            width: 100%;
            max-width: 100%;
        }
        img {
            width: 100%;
            max-width: 100%;
        }
        .round {
            border-radius: 100%;
            box-shadow: 0 13px 26px rgba(#000, .2), 0 3px 6px rgba(#000, .2);
        }
    `

    @property({attribute: true})
    url = ""

    @property({attribute: true})
    alt = ""

    @property({attribute: false})
    round = false

    render() {
        if (!this.url)
            return html`N/A`;
        
        return html`
            <img 
                src="${this.url}" 
                alt="${this.alt}"
                class="${this.round ? "round ": ""}"
            >
        `;
    
    }
}