<script setup lang="ts">
    import style from "@common/style/test.module.css"
    import { ref } from "vue"
    
    const value = ref("hello vue")
    
    let change = (e: Event) => {
        let input = e.target as HTMLInputElement
        value.value = input.value
    }
</script>
<style scoped>
    p {
        color: orange;
    }
    div.border {
        border: 2px solid white;
    }
</style>
<template>
    <div class="border">
        <p class="global-class">{{ value }}</p>
        <label for="inputvue" :class="style.hello">change text</label>
        <input :value="value" @input="change"/>
    </div>
    
</template>