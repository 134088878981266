<script setup lang="ts">
    import { onMounted, ref } from "vue"
    import type { Subscription } from "rxjs"
    import { openMainMenu } from "@common/stores/appstate"
    import TouchGestures from "@common/services/TouchGestures"

    let touch:TouchGestures
    let menuEl = ref<HTMLDivElement | null>(null)
    let sub: Subscription
    let open = ref(false)
    let modal = ref(false)
    
    const clickEvent = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (open.value)
            openMainMenu.next(false)
    }
	let timer = 0
    onMounted(() => {
        sub = openMainMenu.subscribe(o => {
            modal.value = o
            if (o)
                open.value = o
            else
                timer = window.setTimeout(() => open.value = o, 400) // correspond with transition
        })
        touch = new TouchGestures(menuEl.value!, () => console.log("none"), () => openMainMenu.next(false) )
    })
</script>

<style scoped>
	* {
		box-sizing: border-box;
		color: var(--digilean-primary-text);
	}
    div#sidemenu {
        position: fixed;
        z-index: 900;
        left: -100vw;
        top: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: transparent;
        transition: background-color .4s linear;
    }
    div#sidemenu.modal {
        background-color: rgba(0,0,0,0.4);
    }
    div#sidemenu.open {
        left: 0;
    }
    
	div.menu {
		background: var(--digilean-main-menu-background);
        color: var(--digilean-main-menu-text);
		width: 80vw;
        left: -80vw;
		height: 100vh;
		position: absolute;
		top:0;
		/* left:-80vw; */
		padding: 0;
		z-index: 1;
        transition: left .1s linear;
	}
    div#sidemenu.modal div.menu {
        left: 0;
    }
</style>
<template>
    <div id="sidemenu" :class="{modal, open}" @click="clickEvent">
        <div class="menu" ref="menuEl">
            <slot></slot>
        </div>
    </div>
</template>
