import { toastStore } from "./toastStore"
import "./ToastComponent"

export interface Toast {
    type: string
    size: string
    position: string
    msg: string
    click?: Function
}

class ToastService {
    info(msg: string) {
        const toast: Toast = {
            type: "info",
            size: "",
            position: "left",
            msg
        }
        toastStore.add(toast, 10)
    }
    biginfo(msg: string, click?: Function) {
        const toast: Toast = {
            type: "info",
            size: "big",
            position: "left",
            msg
        }
        if (click)
            toast.click = click
        toastStore.add(toast, 20)
    }
    error(msg: string) {
        const toast: Toast = {
            type: "error",
            size: "normal",
            position: "right",
            msg
        }
        toastStore.add(toast)
    }
    success(msg: string) {
        const toast: Toast = {
            type: "success",
            size: "normal",
            position: "right",
            msg
        }
        toastStore.add(toast)
    }
}

export default new ToastService()
