
import oidc from "./digiLeanOidc"
oidc.initialize()

export const login = () => {
    oidc.login()
}

export const logout = () => {
    oidc.signOut()
}
