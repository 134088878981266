import { FirebaseOptions, initializeApp } from "firebase/app"

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyAfusLRgYM_P2dh3QeDEHLhXgaC9uZGq-c",
    authDomain: "digilean-notifications.firebaseapp.com",
    projectId: "digilean-notifications",
    storageBucket: "digilean-notifications.appspot.com",
    messagingSenderId: "438311561936",
    appId: "1:438311561936:web:cbba381b88440783c00cd5",
    measurementId: "G-8VS4395H1L"
}


// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)

// dont use this because it will be intermingled with the SW
//import { getAnalytics } from "firebase/analytics"
//const analytics = getAnalytics(firebaseApp)
