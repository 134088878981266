import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { User } from "@api"

@customElement('profile-image-viewer')
export class ProfileImageViewer extends LitElement {
    private _imageUrl = ""
    
    static styles = css`
        :host {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            height: var(--digilean-image-height, 3rem);
            width: var(--digilean-image-width, 3rem);
            
            border-radius: 50%;
            background: var(--digilean-primary-button);
            color: var(--digilean-main-menu-text);
            text-align: center;
            font-family: "Roboto Mono", monospace;
            cursor: pointer;
            border: 1px solid white;
        }
    `

    @property({attribute: false})
    user: User = {}

    @property({attribute: false})
    initials = false

    render() {
        
        if (!this.user)
            return html`<div class="initials">N/A</div>`
        
        this.title = `${this.user.fullName}`

        if (this.initials && this.user.initials) {
            return html`<span>${this.user.initials}</span>`
        }
        
        if (this.user.profileImageUrl) {
            return html`
                <image-viewer 
                    url="${this.user.profileImageUrl}" 
                    alt="profile badge"
                    .round=${true}
                    >
                </image-viewer>`
        }

        if (this.user.displayName) {
            return html`<span>${this.user.displayName}</span>`
        }
        if (this.user.initials) {
            return html`<span>${this.user.initials}</span>`
        }
    }
}