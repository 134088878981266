import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import type { User } from "@api"
import { getUser } from "@common/stores/usersStore"


@customElement('commented-by-user')
export class ProfileImageViewer extends LitElement {
    _userId: string
    _user: User;
    
    static styles = css`
        :host {
            display: inline-block;
        }
        profile-image-viewer {
            height: 1.6rem;
            width: 1.6rem;
            line-height: 1.6rem;
            font-size: 0.8rem;
            margin-right: 0.5rem;
        }
        span.username {
            font-size: 0.8rem;
            font-weight: bold;
        }
    `;

    set userid(value: string) {
        this._userId = value
        this._user = getUser(value)
        this.requestUpdate()
    }
    get userid(): string {
        return this._userId
    }

    render() {
        if (this._user) {
            return html`
            <div>
                <profile-image-viewer .user=${this._user} .initials=${true}></profile-image-viewer>
                <span class="username">${this._user.fullName}</span>
            </div>
        `
        }
        return html`
            <span>N/A</span>
        `
    }
}

