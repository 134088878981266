import { getMessaging, Messaging, getToken, onMessage, NextFn, MessagePayload } from "firebase/messaging"
import { notificationPermission } from "@common/stores/notifications"
import toast from "@common/components/Toast/toast"
import {firebaseApp} from "./firebaseInit"
import { authUser } from "@common/stores/userStore"
import { pushNotification} from "@app/notifications/pushNotificationHandler"

const vapidKey = "BDCRb-SudoPrtx2MWcGGb85dIkj0KTntVRD40H6u1jiAyXnyscdw-acG31cGBMluUofsGl1X6BdEczrzbQjyPR4"

export let messagingToken = ""

let swRegistration: ServiceWorkerRegistration | null = null

let permission: NotificationPermission = "default"

const enableFirebaseNotification = async (serviceWorkerRegistration: ServiceWorkerRegistration) => {
    const token = await getToken(messaging, {vapidKey, serviceWorkerRegistration})
    messagingToken = token
    console.log("my token is", token)
    return token
}

const enableWhenRead = () => {
    if (permission == "granted" && swRegistration)
        enableFirebaseNotification(swRegistration)  
}

navigator.serviceWorker.ready.then((reg) => {
    swRegistration = reg
    enableWhenRead()
})

authUser.subscribe(au => {
    if (au.loggedIn) {

        notificationPermission.subscribe(p => {
            permission = p
            enableWhenRead()
        })
    }
})

const messaging = getMessaging(firebaseApp)

// when receiving push message while app is active
onMessage(messaging, (payload: MessagePayload) => {
    console.log("message, payload=", payload)
    const str = JSON.stringify(payload)
    toast.info(str)

    if (swRegistration)
        pushNotification(payload, swRegistration)
})
