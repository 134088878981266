import "@common/style/colors.css"
import "@common/style/theme.css"
import "./style/global.css"


//import "@material/mwc-button/mwc-button.js"
//import '@material/mwc-dialog/mwc-dialog.js'
//import '@material/mwc-button/mwc-button.js'
//import '@material/mwc-radio/mwc-radio.js'
//import '@material/mwc-formfield/mwc-formfield.js'
import '@material/mwc-icon/mwc-icon.js'
//import '@material/mwc-list/mwc-list.js'
//import '@material/mwc-list/mwc-list-item.js'
//import '@material/mwc-select/mwc-select.js'
import '@material/mwc-tab-bar/mwc-tab-bar.js'
import '@material/mwc-tab/mwc-tab.js'
//import '@material/mwc-textarea/mwc-textarea.js'
import '@material/mwc-textfield/mwc-textfield.js'


import "@common/components"
import "@common/components/Formatters"
import "@common/components/Images"
import "@common/components/Comments"
import "@common/services/userService"

import "@app/components"
import { router } from "@app/routes"

import { createApp } from "vue"
// import { VueQueryPlugin } from "@tanstack/vue-query";

import MainApp from './App.vue'

const app = createApp(MainApp)
app.use(router)
// app.use(VueQueryPlugin)
app.mount('#app')


// import Board from "@common/components/Boards/Board.vue"
// const BoardCe = defineCustomElement(Board)
// customElements.define('test-board', BoardCe)