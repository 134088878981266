import { map } from "rxjs/operators"
import { cloneDeep } from "lodash-es"
import { BehaviorSubject, Observable } from "rxjs"
import { getLocalStorageJson, setLocalStorageJson} from "@common/services/localStorageService"

export const clonedObservable = <T>(subject: BehaviorSubject<T>) => {
    return subject.pipe(map(data => cloneDeep(data)))
}

export class StoredSubject<T> extends BehaviorSubject<T> {
    /**
     * subject backed by local storage
     * @param key: string for local storage item
     */
    private key: string
    constructor(key: string, initValue: any) {
        let value = getLocalStorageJson(key)
        if (!value)
            value = initValue
        super(value)
        this.key = key
    }
    
    next(value: T) {
        setLocalStorageJson(this.key, value)
        super.next(value)
    }
}

export class DlSubject<T> extends BehaviorSubject<T> {
    getClonedValue(){
        const value = super.getValue()
        return value
    }
}