import type { BoardTask, PersonalWorkList } from "@api"
import { authUser } from "@common/stores/userStore"
import * as service from "@common/services/taskService"
import { BehaviorSubject } from "rxjs"

const myTasksSubject = new BehaviorSubject<PersonalWorkList>({today: [], overdue: [], future:[]});
export const myTasks = myTasksSubject.asObservable()
export const loadMyTasks = async() => {
    const myTasks = await service.getMyTasks()
    myTasksSubject.next(myTasks)
}

export const updateTask = async (task: BoardTask, orgDate: string) => {
    const ids = await service.updateTask(task)
    if (task.boardDate !== orgDate)
        await service.updateTaskDate(task.id!, task.boardDate!)
    loadMyTasks()
}

export const newTask = async (task: BoardTask) => {
    const id = await service.registerNewTask(task)
    loadMyTasks()
}

export const removeTask = async(taskId: number) => {
    await service.deleteTask(taskId)
    loadMyTasks()
}

export const getBoardTask = async (id: number) => {
    const task = await service.getBoardTask(id)
    return task
}


authUser.subscribe(user => {
    if (user.loggedIn)
        loadMyTasks()
})