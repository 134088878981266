import { ref, onMounted, onUnmounted } from "vue"
import type { Observable, Observer, Subscription } from "rxjs"

export const observableComposable = <T>(obs: Observable<T>, defaultValue: T) => {
    
    let obsVal = ref<T>(defaultValue)
    let sub: Subscription

    let observer: Observer<T> = {
        next(val) {
            ///@ts-ignore
            obsVal.value = val
        },
        error(err) {
            console.log(err)
        },
        complete() {
            console.log("done")
        }
    }

    onMounted(() => {
        sub = obs.subscribe(observer)
    })
    onUnmounted(() =>  {
        sub.unsubscribe()
    })
    return obsVal
}