import { useQuery, QueryClient } from "@tanstack/vue-query"

import * as service from "@common/services/taskService"

const queryClient = new QueryClient({
    defaultOptions: { 
        queries: { 
            staleTime: 50000,
        } 
    },
})


export const getMyTasksQuery = () => {
    return useQuery({
        queryClient,
        queryKey: ["mytasks"],
        queryFn: service.getMyTasks
    })
}