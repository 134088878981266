import type { User, UserFullProfile, UserProfileImageInfo } from "@api"
import config from "@common/config"
import backend from "@common/services/backendHttp"
import { authUser, setUserProfile } from "@common/stores/userStore"

const profileImageContainerUrl = `${config.fileStoreUrl}/profile-images`

export const getAllUsers = async () => {
    const all = await backend.get<User[]>("api/user")
    return all
}

export const getUserProfile = async () => {
    const profile = await backend.get<UserFullProfile>("api/user/getfullprofile")
    if (profile.settings && typeof profile.settings.settings === "string") {
        profile.settings = JSON.parse(profile.settings.settings);
    }
    return profile
}

export const getUsersProfileImage = async () => {
    const userpi = await backend.get<UserProfileImageInfo[]>("api/profile/GetUsersWithProfileImage")
    return userpi
}

export const getProfileImageFilestore = (imageSize: string, fileName: string) => {
    return `${profileImageContainerUrl}/${imageSize}-${fileName}`
}



authUser.subscribe(async user => {
    if (user && user.loggedIn) {
        const up = await getUserProfile()
        setUserProfile(up)
    } else {
        setUserProfile({})
    }
})