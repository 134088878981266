import type { Toast } from "./toast"
import { BehaviorSubject } from "rxjs";

class ToastSubject extends BehaviorSubject<Toast[]> {

    add(toast:Toast, timeout?: number) {
        if (!timeout)
            timeout = 20
        const list = this.getValue()
        list.push(toast)
        this.next(list)
        setTimeout(() => {
            this.remove(toast)
        }, timeout * 1000)
    }

    remove(toast: Toast) {
        const list = this.getValue()
        const index = list.indexOf(toast)
        if (index > -1) {
            list.splice(index, 1)
            this.next(list)
        }
    }

    reset(){
        this.next([])
    }
}

export const toastStore = new ToastSubject([])