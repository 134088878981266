<script setup lang="ts">
    import Login from "./Login.vue"
    import { authUser } from "@common/stores/userStore"
    import { openMainMenu, loadingOrSaving } from "@common/stores/appstate"
    import { faBars, faSync } from "@fortawesome/free-solid-svg-icons"
    import { faPlusSquare } from "@fortawesome/free-regular-svg-icons"
    import { observableComposable } from "@common/directives/rxstate"
    import { goto } from "@app/routes"

    import SideMenu from "@app/components/SideMenu.vue"
	import MainMenu from "@app/MainMenu.vue"
    import "@app/swloader"
    
    let userAuth = observableComposable(authUser)
    
    let loading = observableComposable(loadingOrSaving)

    function openMenu() {
        openMainMenu.next(true)
    }

</script>

<template>
    <section v-if="userAuth?.loggedIn">
        <header>
            <div class="header-section">
                <fa-icon class="open-menu-button" .icon="faBars" @click="openMenu"></fa-icon>
                <a href="/" @click="goto">
                    <h1>DigiLEAN</h1>
                </a>
                <fa-icon class="primary-button loading" :class="{show: loading}" .icon="faSync"></fa-icon>
            </div>
            <div class="header-section">
                <a href="/about" @click="goto">
                    <digilean-logo></digilean-logo>
                </a>
                <logged-in-profile-image-viewer :initials="true" slot="button"></logged-in-profile-image-viewer>
                <fa-icon class="primary-button" .icon="faPlusSquare"></fa-icon>
            </div>
        </header>

        <!-- <label for="digi-cb">Checkbox</label>
        <digilean-checkbox id="digi-cb" name="digi-cb"></digilean-checkbox> -->
        <fa-icon2></fa-icon2>
        <router-view></router-view>
        <toast-messages></toast-messages>
    </section>
    <section v-else>
        <Login></Login>
    </section>
    <SideMenu>
        <MainMenu />
    </SideMenu>
</template>

<style scoped>
    a {
        text-decoration: none;
    }
    header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        padding: 0.5rem 0.5rem 0.5rem 1rem;
        flex-basis: auto;
    }
    div.header-section {
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;
        gap: 0.5rem;

    }
    section {
        flex-basis: auto;
        flex-grow: 1;
    }
    h1 {
        padding: 0;
        margin: 0 0 0 1rem;
        font-size: 1.5rem;
        font-weight: 500;
        color: var(--digilean-main-menu-text);
    }
    profile-image-viewer {
        height: 2rem;
        width: 2rem;
        line-height: 2rem;
        font-size: 0.8rem;
    }
    fa-icon.open-menu-button {
        cursor: pointer;
    }
    fa-icon {
        --digilean-fa-icon-width: 2rem;
        --digilean-fa-icon-height: 2rem;
    }
    digilean-logo, logged-in-profile-image-viewer {
        --digilean-image-height: 2rem;
        --digilean-image-width: 2rem;
    }

    digilean-check-green-animated {
        --digilean-image-height: 8rem;
        --digilean-image-width: 8rem;
    }
    .update-version {
        display: none;
    }
    .update-version.show {
        display: inline-block;
    }
    
    .spaceholder {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }
    .network-state {
        display: inline-block;
    }
    fa-icon.loading {
        visibility: hidden;
        --digilean-fa-icon-width: 1.5rem;
        --digilean-fa-icon-height: 1.5rem;
    }
    fa-icon.loading.show {
        visibility: visible;
        animation: spin-animation 1s infinite linear;
    }
    popdown-menu {
        background: var(--digilean-main-menu-background);
        --digilean-image-height: 3rem;
        --digilean-image-width: 3rem;
    }
    .logout {
        color: var(--digilean-primary-text);
        cursor: pointer;
    }
</style>
