
import type { DigiLeanAuthUser } from "@common/model/types"
import { formatLoggedInUser } from "@common/services/userAuthService"
import type { UserFullProfile } from "@api"
import { cloneDeep } from "lodash"
import { BehaviorSubject } from "rxjs"
import { clonedObservable, StoredSubject } from "@common/stores/reactive"
import type {User} from "oidc-client-ts"

const authUserSubject = new StoredSubject<DigiLeanAuthUser>("digiLeanAuthUser", {loggedIn: false});
export const authUser = clonedObservable(authUserSubject)
export const setDigiAuthUser = (user: DigiLeanAuthUser) => {
    authUserSubject.next(user)
}

const userProfileSubject = new BehaviorSubject<UserFullProfile>({})
export const userProfile = userProfileSubject.asObservable()

export const setAuthUserFromOidc = async (user: User | null) => {
    if (user) {
        const authuser = formatLoggedInUser(user)
        authUserSubject.next(authuser)
    }
    else {
        setUserLoggedOut()
    }
}

export const setUserProfile = (userProfile: UserFullProfile) => {
    userProfileSubject.next(userProfile)
}

export const setUserLoggedOut = () => {
    authUserSubject.next({loggedIn: false})
}
const getCurrentUserClone = (): DigiLeanAuthUser => {
    const current = authUserSubject.getValue()
    const clone: DigiLeanAuthUser = cloneDeep(current)
    return clone
}
