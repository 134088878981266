<script setup lang="ts">
    import { ref, onMounted, watch } from "vue"
    import { useRoute } from "vue-router"
    import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
    import { faCheckSquare, faLightbulb } from "@fortawesome/free-regular-svg-icons"
    import { gotoPath } from "@app/routes"
    let tabbar = ref<HTMLElement | null>(null)
    const route = useRoute()
    watch(route, (r) => {
        console.log("route changed", r.path)
        if (r.path == "/" || r.path == "/mytasks") {
            ///@ts-ignore
            tabbar.value.activeIndex = 0
        }
    })
    function goToView(i: number) {
        let route = "/"
        switch (i) {
            case 0:
                route = "mytasks"
                break
            case 1:
                route = "myimprovements"
                break
            case 2:
                route = "myincidents"
                break
            default: "/"
        }
        gotoPath(route)
    }
    let initial = true
    const tabChanged = (e: CustomEvent<{index: number}>) => {
        console.log(e.detail.index)
        if (initial) {
            initial = false
            return
        }
        let activeIndex = e.detail.index
        goToView(activeIndex)
    }
    onMounted(async () => {
        tabbar.value?.addEventListener("MDCTabBar:activated", tabChanged as EventListener)
        if (route.path == "/") {
            goToView(0)
        }
        if (route.path == "/myimprovements") {
            /// @ts-ignore
            tabbar.value.activeIndex = 1
        }
        if (route.path == "/myincidents") {
            /// @ts-ignore
            tabbar.value.activeIndex = 2
        }
    })
</script>

<template>
    <article>
        <mwc-tab-bar ref="tabbar">
            <mwc-tab icon="link" stacked has-image-icon>
                <fa-icon
                    slot="icon"
                    .icon="faCheckSquare"
                    title="Task"
                >
                </fa-icon>
            </mwc-tab>
            <mwc-tab icon="link" stacked has-image-icon>
                <fa-icon
                    slot="icon" 
                    .icon="faLightbulb"
                    title="Comments"
                >
                </fa-icon>
            </mwc-tab>
            <mwc-tab icon="link" stacked has-image-icon>
                <fa-icon
                    slot="icon"
                    .icon="faExclamationTriangle"
                    title="attachments"
                >
                </fa-icon>
            </mwc-tab>
        </mwc-tab-bar>
        <router-view></router-view>
    </article>
</template>

<style scoped>
    article {
        display: flex;
        flex-direction: column;
        color: var(--digilean-primary-text);
        height: 100%;
    }
    section {
        background: var(--digilean-primary-background);
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    mwc-tab-bar {
        --mdc-theme-primary: var(--digilean-main-menu-text);
        --mdc-text-transform: none;
        --mdc-typography-button-text-transform: none;
        --mdc-tab-color-default: var(--digilean-disabled);
        --mdc-tab-text-label-color-default: var(--digilean-disabled);
        /* --mdc-tab-stacked-height: 100px; */
    }
    mwc-tab {
        --mdc-theme-primary: var(--digilean-main-menu-text);
        --mdc-tab-color-default: var(--digilean-disabled);
        --mdc-tab-text-label-color-default: var(--digilean-main-menu-text);
    }
    fa-icon {
        --digilean-fa-icon-width: 1.8rem;
        --digilean-fa-icon-height: 1.8rem;
    }
</style>