
import Home from "@app/views/Home.vue"
import MyTasks from "@app/views/Tasks/MyTasks.vue"
import MyImprovements from "@app/views/MyImprovements.vue"
import MyIncidents from "@app/views/MyIncidents.vue"

import TaskDetails from "@app/views/Tasks/Details/Index.vue"

import About from "@app/views/About.vue"

import * as VueRouter from "vue-router"
import { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        redirect: "/mytasks",
        component: Home,
        children: [
            {
                path: 'mytasks',
                component: MyTasks,
            },
            {
                path: 'myimprovements',
                component: MyImprovements,
            },
            {
                path: 'myincidents',
                component: MyIncidents,
            },
        ]
    },
    {
        path: "/task/:id",
        component: TaskDetails
    },
    {
        path: "/about",
        component: About
    }

]

export const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes
})

export const goto = (e: any) => {
    e.preventDefault()
    const path = e.currentTarget.pathname
    gotoPath(path)
}

export const gotoPath = (path: string) => {
    router.push({path})
}

export const getRoute = () => {
    return router.currentRoute
}