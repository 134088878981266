<script setup lang="ts">
    import {onMounted, ref} from "vue"
    import { useRoute } from "vue-router"
    import { getBoardTask } from "@common/stores/taskStore"
    import { faCheckSquare, faComment, faPaperclip } from "@fortawesome/free-solid-svg-icons"
    import type { BoardTask } from "@api"
    
    import Info from "./Info.vue"
    import Comments from "./Comments.vue"
    import Attachments from "./Attachments.vue"

    const route = useRoute()
    const task = ref<BoardTask>({ id: 0, commentCount: 0, attachmentCount: 0})
    
    // fetch the user information when params change
    const getTask = async () => {
        const idStr = route.params.id as string
        const id = parseInt(idStr)
        console.log(`id=${id}`)
        if (!id)
            return
        let t: BoardTask = { id, commentCount: 0, attachmentCount: 0}
        try {
            t = await getBoardTask(id)
        }
        catch (err) {
            console.log(err)
        }
        task.value = t
    }

    //const idRef = ref(0)
    
    let tabbarEl = ref<HTMLElement | null>(null)
    let activeIndex = ref(0)
    
	onMounted(async () => {
        tabbarEl.value?.addEventListener("MDCTabBar:activated", (e) => {
            ///@ts-ignore
            activeIndex.value = e.detail.index
        })
        getTask()
    })
    
</script>
<template>
    
    <mwc-tab-bar ref="tabbarEl">
        <mwc-tab label="Task" icon="info" stacked hasImageIcon>
            <fa-icon slot="icon" 
                .icon="faCheckSquare"
                title="Task">
            </fa-icon>
        </mwc-tab>
        <mwc-tab label="Comments" icon="message" stacked hasImageIcon>
            <fa-icon slot="icon" 
                .icon="faComment"
                .counter="task.commentCount"
                title="Comments">
            </fa-icon>
        </mwc-tab>
        <mwc-tab label="Attachments" icon="link" stacked has-image-icon>
            <fa-icon slot="icon" 
                .icon="faPaperclip"
                .counter="task.attachmentCount"
                title="attachments">
            </fa-icon>
        </mwc-tab>
    </mwc-tab-bar>
    
    <section v-if="task">
        <Info :task="task" v-if="activeIndex === 0" />
        <Comments :task="task" v-if="activeIndex === 1" />
        <Attachments :task="task" v-if="activeIndex === 2" />
    </section>
</template>
<style scoped>
    article {
        display: flex;
        flex-direction: column;
        color: var(--digilean-primary-text);
        height: 100%;
    }
    section {
        background: var(--digilean-primary-background);
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }
    mwc-tab-bar {
        --mdc-theme-primary: var(--digilean-main-menu-text);
        --mdc-text-transform: none;
        --mdc-typography-button-text-transform: none;
        --mdc-tab-color-default: var(--digilean-disabled);
        --mdc-tab-text-label-color-default: var(--digilean-disabled);
        /* --mdc-tab-stacked-height: 100px; */
    }
    fa-icon {
        --digilean-fa-icon-width: 1.2rem;
        --digilean-fa-icon-height: 1.2rem;
    }
</style>