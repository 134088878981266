<script setup lang="ts">
    import {onMounted, ref} from "vue"
    import { authUser } from "@common/stores/userStore"
    import { logout } from "@common/authentication/authenticator"
    import { observableComposable } from "@common/directives/rxstate"
    import {messagingToken} from "@app/notifications/notificationsFirebase"
    import { notificationPermission } from "@common/stores/notifications"
    import { enablePushNotification } from "@common/services/notificationsApi"
    import toast from "@common/components/Toast/toast"
    import { setBadgeCount, isAppBadgeSupported } from "@common/services/appBadgeService"

    const fbToken = ref("")
    
    let user = observableComposable(authUser, {loggedIn: false})
    let permission = observableComposable(notificationPermission, "default")
    
    let badgeText = ref(`Supported: ${isAppBadgeSupported}`)

    onMounted(() => {
        fbToken.value = messagingToken
    })

    function isNotificationPermissionGranted() {
        return permission.value == "granted"
    }
    async function testBadge() {
        try {
            let res = await setBadgeCount(1)
            badgeText.value = "Ok"
        }
        catch(error: any) {
            badgeText.value = error.message
        }
        
    }
    // let profile = observableComposable(userProfile)

</script>
<style scoped>
    section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
    }
    div.main {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    div.user {
        margin: 1rem 0 1rem 0;
        text-align: left;
    }
    h3 {
        margin-bottom: 0.4rem;
    }
    p {
        margin-top: 0.2rem;
    }
    nav.menu-items {
        text-align: left;
    }
    
    div.logout {
        display: inline-flex;
        flex-direction: column;
        flex-basis: 2rem;
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: auto;
    }
    div.logout button {
        flex-basis: 2rem;
        text-transform: capitalize;
        border-radius: 0;
        padding: 0;
    }
    div.language {
        margin-bottom: 2rem;
    }
</style>
<template>
    <div class="main">
        <div class="logo">
            <digilean-logo></digilean-logo>
        </div>
        <div class="user">
            <h3>{{user.displayName}}</h3>
            <p>{{user.userName}}</p>
            <logged-in-profile-image-viewer></logged-in-profile-image-viewer>
        </div>
        <!-- <nav class="menu-items">
            <Toggle />
        </nav> -->
    </div>
    <div class="language">
        &nbsp;
    </div>
    <div>
        notification permission: {{permission}}<br>
        firebase token: {{fbToken}}
        <button v-if="!isNotificationPermissionGranted()" 
            @click="enablePushNotification()">Enable push
        </button><br>
        <br>
        <button @click="testBadge">Test badge</button>
        <small>{{ badgeText }}</small>
        <br>
        <br>
        <button @click="toast.info('test toast')">Toast</button>
        <br>
    </div>
    <div class="logout">
        <digilean-button @click="() => logout()">Log out</digilean-button>
    </div>
</template>