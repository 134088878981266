import { WebStorageStateStore, UserManager } from "oidc-client-ts"
import type { UserManagerSettings, UserLoadedCallback, UserSignedOutCallback } from "oidc-client-ts"
import { appInitialized } from "@common/stores/appstate"
import { setAuthUserFromOidc } from "@common/stores/userStore"
import config from "@common/config"
const logPrefix = "DigiLeanOIDC::"

const oidcConf: UserManagerSettings = {
    authority: config.authUrl,
    client_id: "webclient",
    redirect_uri: `${window.location.origin}/callback.html`,
    response_mode: "query",
    response_type: "code",
    scope:"openid profile api.read",
    loadUserInfo: true,
    post_logout_redirect_uri: window.location.origin,
    userStore: new WebStorageStateStore({store: localStorage}),
    accessTokenExpiringNotificationTimeInSeconds: 60,
    silentRequestTimeoutInSeconds: 1000,
    automaticSilentRenew: false,
    silent_redirect_uri: `${window.location.origin}/silent-renew.html`
}
const mgr = new UserManager(oidcConf)
const initialize = async () => {
    const user = await mgr.getUser()
    if (!user || user.expired) {
        log("Not logged in")
        if (!user)
            log("No user")
        else
            log(`User expired=${user.expired}`)
        login()
    }
    else
        setAuthUserFromOidc(user)
    
    appInitialized.next(true)
}
const login = () => {
    mgr.signinRedirect({ 
        state: window.location.href
    })
}
const addUserLoaded = (fn: UserLoadedCallback) => {
    mgr.events.addUserLoaded(fn)
}
const addUserSignedOut = (fn: UserSignedOutCallback) => {
    mgr.events.addUserSignedOut(fn)
}
let events = {
    addUserLoaded,
    addUserSignedOut
}
const getLoggedInUser = async () => {
    const user = await mgr.getUser()
    if (!user || user.expired) {
        log("DigiLEAN auth:: no user means not logged in")
        return login()
    }
    else
        return user
}
const signOut = () => {
    logDebug("sign out of digilean")
    setAuthUserFromOidc(null)
    mgr.signoutRedirect()
}
const log = (msg: string) => console.log(`${logPrefix} ${msg}`)
const logDebug  = (msg: string) => console.debug(`${logPrefix} ${msg}`)
const logError = (msg: string) => console.error(`${logPrefix} ${msg}`)

let isAlreadyRenewing = false
const silentRenew = async () => {
    logDebug("silentRenew:: manual start")
    if (isAlreadyRenewing)
        return
    try {
        isAlreadyRenewing = true
        const user = await mgr.signinSilent()
        logDebug("silentRenew:: renewal successful")
        setAuthUserFromOidc(user)
        return user
    } catch(err: any) {
        logError(`silentRenew:: Error from signinSilent: ${err.message}`)
    }
    finally {
        isAlreadyRenewing = false
    }
}

export const callback = () => {
    log("callback")
    console.log("digi oidc config", oidcConf)
    mgr.signinRedirectCallback().then(function(user) {
        console.log("callback-oidc.js")
        console.log(user.state)

        if (user.state) {
            console.log("user.state", user.state)
            window.location.replace(user.state as string)
        }
        else
            window.location.replace(window.location.origin)
    }).catch(function(e) {
        logError("Something went wrong on callback")
        logError(e)
    })
}

export default { 
    initialize,
    events,
    getLoggedInUser,
    silentRenew,
    login,
    signOut
}