<script setup lang="ts">
    import { ref } from "vue"

    const count = ref(0)
    
    let increment = () => count.value ++
</script>

<template>
    <h1>About</h1>
    <button @click="increment">increment</button>
    <span>Count is: {{ count }}</span>

    <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/mytasks">My tasks</router-link>
        <router-link to="/myimprovements">My improvements</router-link>
        <router-link to="/myincidents">My incidents</router-link>
    </nav>
    
</template>

<style scoped>
    h1 {
        color: red;
    }
    nav {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        color: white;
        gap: 1rem;
    }
    a, a:visited {
        color: white;
    }
</style>
