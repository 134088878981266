let baseUrlEnv = ""
let authUrlEnv = ""
let fileStoreUrlEnv = ""
let useSwEnv = ""

try {
    ///@ts-ignore
    baseUrlEnv = env.DIGILEAN_BASE_URL
    ///@ts-ignore
    authUrlEnv = env.DIGILEAN_AUTH_URL
    ///@ts-ignore
    fileStoreUrlEnv = env.DIGILEAN_FILESTORAGE_URL
    ///@ts-ignore
    useSwEnv = env.DIGILEAN_USESW
} catch (e) {}

if (location.hostname.includes("digilean.tools"))
    useSwEnv = "true"

let baseUrl = "https://dev.digilean.tools"
if (baseUrlEnv)
    baseUrl = baseUrlEnv

let authUrl = "https://authdev.digilean.tools"
if (authUrlEnv)
    authUrl = authUrlEnv

let fileStoreUrl = "https://testdigileanfiles.blob.core.windows.net"
if (fileStoreUrlEnv)
    fileStoreUrl = fileStoreUrlEnv

let useSw = false
if (useSwEnv == "true")
    useSw = true

const apiurl = `${baseUrl}/api`
const signalrUrl = `${baseUrl}/hubs`

export default { baseUrl, apiurl, authUrl, fileStoreUrl, signalrUrl, useSw }